<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:57
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-20 16:00:11
 * @FilePath: /mediatom-web/src/viewsForManage/dashboard/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="dashboard-container" :style="{'font-size': fontSize + 'px'}">
    <RealTimeCard />
    <div class="home-main">
      <div class="home-main-top">
        <m-ratio-box class="ratio-box" :ratio="24">
          <div class="module-wrapper">
            <div class="module-item left">
              <DataRank :adminUserList="adminUserList" :isFixed="true"/>
            </div>
            <div class="module-item center">
              <SumData/>
            </div>
            <div class="module-item right">
              <DataRank :adminUserList="adminUserList" :fixedLeft="false" :isFixed="true"/>
            </div>
          </div>
        </m-ratio-box>
      </div>
      <div class="home-main-bottom">
        <m-ratio-box class="ratio-box" :ratio="27">
          <div class="module-wrapper">
            <div class="module-item-adx">
              <AdxReportChart/>
            </div>
            <div class="module-item-log">
              <LogData/>
            </div>
          </div>
        </m-ratio-box>
      </div>
      <div class="chart-selection">
        <a-dropdown>
          <a-button type="">
            图表行数 <a-icon type="down" />
          </a-button>
          <a-menu slot="overlay" @click="changeRowNum" :selectedKeys="['' + currentRowNum]">
            <a-menu-item key="0">
              <a href="javascript:;">0行</a>
            </a-menu-item>
            <a-menu-item key="1">
              <a href="javascript:;">1行</a>
            </a-menu-item>
            <a-menu-item key="2">
              <a href="javascript:;">2行</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div class="home-main-bottom" v-for="(item ,index) in chartInfoList" :key="index">
        <m-ratio-box class="ratio-box" :ratio="24">
          <div class="module-wrapper">
            <div class="module-item" v-for="(child, childIndex) in item" :key="childIndex">
              <DataRank :adminUserList="adminUserList" :rowIndex="index" :colIndex="childIndex" :isFixed="false"/>
            </div>
          </div>
        </m-ratio-box>
      </div>
    </div>
  </div>
</template>

<script>
import SumData from './models/SumData'
import autoFontSize from '@/mixins/autoFontSize'
import AdxReportChart from './models/AdxReportChart'
import RealTimeCard from './models/RealTimeCard'
import LogData from './models/LogData'
import DataRank from './models/DataRank'
import { mapState } from 'vuex'
import { settingLayout } from '@/apiForManage/dashboard'
import mixGetList from '@/mixins/getListForManage'

const defaultChartInfoListItem = [
  {
    type: 'bar',
    target: 'request'
  },
  {
    type: 'piev',
    target: 'advRequest'
  }
  // {
  //   type: 'row',
  //   target: 'request'
  // }
]
export default {
  name: 'Home',
  components: { SumData, AdxReportChart, RealTimeCard, DataRank, LogData },
  mixins: [autoFontSize, mixGetList],
  data () {
    return {
      currentRowNum: undefined
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      dashboardLayout: (state) => state.user.dashboardLayoutForMergex,
      chartInfo: (state) => state.user.dashboardLayoutForMergex.chartInfo,
      chartInfoRight: (state) => state.user.dashboardLayoutForMergex.chartInfoRight,
      hasAdmin: (state) => state.user.hasAdmin,
      chartInfoList: (state) => state.user.dashboardLayoutForMergex.chartInfoList
    })
  },
  async created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.currentRowNum = this.chartInfoList.length
    await this.getAdminUserList()
  },
  methods: {
    // 修改布局行数
    changeRowNum (e) {
      this.currentRowNum = +e.key
      if (this.currentRowNum === this.chartInfoList.length) {
        // 未改变
      } else if (this.currentRowNum < this.chartInfoList.length) {
        // 减少行数
        const newChartInfoList = this.chartInfoList.slice(0, this.currentRowNum)
        this.updateChartInfoList(newChartInfoList)
      } else {
        // 增加行数
        const addNum = this.currentRowNum - this.chartInfoList.length
        const newChartInfoList = [...this.chartInfoList]
        for (let i = 0; i < addNum; i++) {
          // newChartInfoList.push(defaultChartInfoListItem)
          if (this.hasAdmin) {
            // 该账户下有子管理员
            if (i !== 2) {
              newChartInfoList.push([
                ...defaultChartInfoListItem,
                {
                  type: 'row',
                  target: 'qpsPrice'
                }
              ])
            } else {
              newChartInfoList.push([
              ...defaultChartInfoListItem,
              {
                type: 'row',
                target: 'qpsPrice'
              }
            ])
            }
          } else {
            // 该账户下没有子管理员
            if (this.chartInfoList.length === 0) {
              // 添加第一列
              newChartInfoList.push([
                ...defaultChartInfoListItem,
                {
                  type: 'row',
                  target: 'request'
                }
              ])
            } else if (this.chartInfoList.length === 1) {
              // 添加第二列
              newChartInfoList.push([
                ...defaultChartInfoListItem,
                {
                  type: 'row',
                  target: 'advRequest'
                }
              ])
            }
          }
        }
        this.updateChartInfoList(newChartInfoList)
      }
    },
    // 更新布局
    async updateChartInfoList (chartInfoList) {
      // 修改布局
      const resp = await settingLayout({
        chartInfo: this.chartInfo,
        chartInfoRight: this.chartInfoRight,
        chartInfoList: chartInfoList
      })
      if (resp.code === 200) {
        // 重新获取info
        this.$store.dispatch('GetInfo').then(() => {
          this.currentRowNum = this.chartInfoList.length
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.dashboard-container {
  // min-width: 1280px;
  padding: calc(16em / 14);
  display: flex;
  flex-direction: column;
  background-color: #F5F6FA;
  .home-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    .home-main-bottom, .home-main-top {
      margin-top: calc(16em / 14);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      flex: 1;
      position: relative;
    }
    .home-main-bottom{
      .module-wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
      }
      .module-item {
        background: #fff;
        border: 1px solid @modelBorderColor;
        box-shadow: @modelShadow;
        border-radius: @mediumRadius;
        height: 100%;
        position: relative;
        min-width: calc((100% - 3 * 10em / 14) / 3);
        max-width: calc((100% - 3 * 10em / 14) / 3);
        flex: 1 auto;
      }
      .module-item-adx{
        height: 100%;
        position: relative;
        flex: 1 auto;
      }
      .module-item-log{
        flex: 1 auto;
        background: #fff;
        border: 1px solid @modelBorderColor;
        box-shadow: @modelShadow;
        border-radius: @mediumRadius;
        height: 100%;
        position: relative;
        margin-left: calc(15em /14);
        min-width: 28.13%;
        max-width: 28.13%;
      }
    }
    .home-main-top{
      .module-wrapper{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
      }
      .module-item {
        background: #fff;
        border: 1px solid @modelBorderColor;
        box-shadow: @modelShadow;
        border-radius: @mediumRadius;
        height: 100%;
        position: relative;
        flex: 1 auto;
        &.left, &.right{
          min-width: 28.13%;
          max-width: 28.13%;
        }
        &.center{
          margin: 0 calc(15em/ 14);
        }
      }
      .module-item-adx{
        min-width: calc((100% - 8em / 14) / 3 * 2);
        max-width: calc((100% - 8em / 14) / 3 * 2);
        height: 100%;
        position: relative;
      }
    }
    .chart-selection{
      width: 120px;
      margin-top: calc(16em / 14);
    }
  }
}
</style>
