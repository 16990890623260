<template>
  <a-spin :spinning="spinning" wrapperClassName="spin-wrapper">
    <div class="log-data-container">
      <HomeTitile title="最新动态" class="title" />
      <div class="row-list">
        <div class="row-head">
          <div class="name">时间</div>
          <div class="value">内容</div>
        </div>
        <div class="list-wrapper">
          <div class="row-item" v-for="(item, index) in dataList" :key="index">
            <div class="name">
              {{ item.createTimeWithoutYear }}
            </div>
            <div class="value content" @click="handleSkipToLog">
              <MessageSvg class="svg-icon" />
              <div class="inner text-ellipsis">
                {{ getActionText(item) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import HomeTitile from '../../components/homeTitle.vue'
import { logPage } from '@/apiForManage/dashboard'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
import MessageSvg from './svgs/message.svg?inline'
import { typeList, actionList } from '@/viewsForManage/Personal/Log/typeList'
export default {
  name: 'LogData',
  mixins: [mixRateAndMoneyMethod],
  components: {
    HomeTitile,
    MessageSvg
  },
  data () {
    return {
      dataList: [],
      isLoading: false,
      typeList
    }
  },
  created () {
    this.getData()
  },
  methods: {
    handleSkipToLog () {
      this.$router.push({
        name: 'log'
      })
    },
    async getData () {
      this.spinning = true
      const { data = {} } = await logPage({
        page: 1,
        limit: 7
      })
      const { items = [] } = data
      this.dataList = items
      this.spinning = false
    },
    getActionText (item) {
      return `${item.username}${this.getActionName(item.operateAction)}${this.getOperateTypeText(item.operateType)}`
    },
    getActionName (id) {
      return actionList.find((item) => item.id === id)?.name
    },
    getOperateTypeText (id) {
      return typeList.find((item) => item.id === id)?.name
    }
  }
}
</script>

<style lang="less" scoped>
.log-data-container {
  padding: @smallMargin 0 8px 0;
  color: #5a607f;
  height: 100%;
  display: flex;
  flex-direction: column;
  .title {
    padding-left: @smallMargin;
  }
  .row-list {
    font-size: calc(13em / 14);
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    .row-head {
      height: calc(38em / 14);
      line-height: calc(38em / 14);
      width: 100%;
      background-color: #faf8ff;
      display: flex;
      font-size: 1em;
      font-weight: 500;
    }
    .list-wrapper,
    .row-head {
      padding: 0 @smallMargin;
    }
    .row-head,
    .row-item {
      .name {
        width: 45%;
        text-align: left;
        // padding-left: @smallMargin;
      }
      .value {
        width: 55%;
        max-width: 50%;
        text-align: left;
        display: flex;
        align-items: center;
        &.content{
          display: flex;
          .svg-icon {
            width: calc(12em / 14);
            height: calc(12em / 14);
            margin-right: calc(5em / 14);
          }
          .inner {
            flex: 1 auto;
          }
        }
      }
    }
    .row-item {
      display: flex;
      flex: 1 auto;
      line-height: 100%;
      align-items: center;
      .value {
        cursor: pointer;
      }
    }
  }
  .list-wrapper {
    flex: 1 auto;
    display: flex;
    flex-direction: column;
    .row-item {
      border-bottom: 1px solid rgba(21, 34, 50, 0.15);
      &:last-child {
        border: none;
      }
    }
  }
}
.spin-wrapper {
  height: 100%;
  ::v-deep {
    .ant-spin-container {
      height: 100%;
    }
  }
}
</style>
